import React from "react";
import { container } from "./SocialShare.module.css"


const SocialShare = ({postTitle, postUrl}) => {

    return(
      <div class={ container }>
        <head>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
          >
          </link>
        </head>

        <a href={   // Facebook
            "https://www.facebook.com/sharer/sharer.php?u=" + postUrl
        }
        class="facebook-btn"
        target="_blank"
        rel="noopener noreferrer"
        >
          <i class="fab fa-facebook-f"></i>
        </a>

        <a href={   // Twitter
            "https://twitter.com/share?url=" + postUrl +
            "&text=" + postTitle +
            "&via" + 
            "twitterHandle"
        }
        class="twitter-btn"
        target="_blank"
        rel="noopener noreferrer"
        >
          <i class="fab fa-twitter"></i>
        </a>

        <a href={   // whatsapp
            "https://api.whatsapp.com/send?text=" + postTitle + "\n " + postUrl
        }
        class="whatsapp-btn"
        target="_blank"
        rel="noopener noreferrer"
        >
            <i class="fab fa-whatsapp"></i>
        </a>
        
               
        <a href={   // viber
            "https://3p3x.adj.st/?adjust_t=u783g1_kw9yml&adjust_fallback=https%3A%2F%2Fwww.viber.com%2F%3Futm_source%3DPartner%26utm_medium%3DSharebutton%26utm_campaign%3DDefualt&adjust_campaign=Sharebutton&adjust_deeplink=" + encodeURIComponent("viber://forward?text=" + encodeURIComponent(postTitle + " " + postUrl))
        }
        class="viber-btn"
        target="_blank"
        rel="noopener noreferrer"
        >
            <i class="fab fa-viber"></i>
        </a>
        
      </div>
    );
}


export default SocialShare;