import PropTypes from "prop-types";
import React from "react";
import { css } from "@emotion/react";
import {DesktopMenu, MobileMenu} from "./Menu";
import { Link } from "gatsby";
import COLORS from "../styles/colors"
import logo from "../images/logo/logo_gold.svg"


const sm = "sm"
const lg = "lg"
export const HEADER_HEIGHTS = {
  sm: 60,
  lg: 90
};

const LOGO_PERCENTAGE = 0.5
const LOGO_HEIGHT = {
  sm: HEADER_HEIGHTS[sm]*LOGO_PERCENTAGE,
  lg: HEADER_HEIGHTS[lg]*LOGO_PERCENTAGE
};

const Header = () => (
  <header
    css={css`
      position: fixed;
      height: ${HEADER_HEIGHTS[sm]}px;
      border-bottom: 1px solid ${COLORS.black};
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: white;
      z-index: 20;
      padding: 0px 15px;

      @media (min-width: 768px) {
        height: ${HEADER_HEIGHTS[lg]}px;
        padding: 0px 30px;
      }
    `}
  >
    <Link
      to={`/`}
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <img
        src={logo}
        alt="logo"
        css={css`
          height: ${LOGO_HEIGHT[sm]}px;
          padding-right: 15px;
          
          @media (min-width: 768px) {
            height: ${LOGO_HEIGHT[lg]}px;
            padding-right: 30px;
          }
        `}
      />
      <h3
        css={css`
          letter-spacing: 0.1em;
          font-weight: bold;
          font-size: 24px;

          @media (min-width: 768px) {
            font-size: 30px;
            border-right: 1px solid grey;
            margin-right: 18px;
            padding-right: 18px;
          }
        `}
      >
        Текстури
      </h3>
      <h5
        css={css`
          letter-spacing: 0.1em;
          font-size: 15px;
          margin-top: 5px;
          display: none;

          @media (min-width: 768px) {
            font-size: 19px;
            display: block;
          }
        `}
      >
        Ахинора А.
      </h5>
    </Link>

    <DesktopMenu
      css={css`
        height: ${LOGO_HEIGHT[sm]}px;
        padding: 0px 0px 0px 15px;

        @media (min-width: 768px) {
          height: ${LOGO_HEIGHT[lg]}px;
          padding: 0px 0px 0px 30px;
        }
      `}
    />
    <MobileMenu />
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
