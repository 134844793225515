import React from "react";
import { Global, css } from "@emotion/core";

const GlobalFonts = () => (
  <Global
    styles={css`

      * {
        box-sizing: border-box;
        margin: 0;
      }
      html,
      body {
        margin: 0;
        font-size: 16px;
        font-family: "lora", serif;
        line-height: 1.4em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      a,
      a:visited {
        color: inherit;
        text-decoration: none;
      }

      button {
        padding: 0px;

        &:hover,
        &:focus {
          outline: none;
        }
      }

      img {
        max-width: 100%;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: normal;
      }
    `}
  />
);

export default GlobalFonts;
