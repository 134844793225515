import React from "react";
import { Global, css } from "@emotion/react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import GlobalFonts from "../fonts/fonts";
import LoadProvider from "../providers/LoadProvider";
import ResizeProvider from "../providers/ResizeProvider";
import Transition from "./transition";
import COLORS from "../styles/colors"



// Make scroll behavior of internal links smooth - works with <a> tags
if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]', {
    speed: 800,
    speedAsDuration: true,
    easing: 'easeInOutCubic'
  })
}


const GlobalStyles = () => (
  <Global
    styles={css`
      * {
        box-sizing: border-box;
        margin: 0;
      }
      html,
      body {
        margin: 0;
        font-size: 18px;
        font-family: "lora", serif;
        line-height: 1.4em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow-x: hidden;
        color: ${COLORS.black};
        background: ${COLORS.white};
      }

      a,
      a:visited {
        color: inherit;
        text-decoration: none;
      }

      button {
        padding: 0px;

        &:hover,
        &:focus {
          outline: none;
        }
      }

      img {
        max-width: 100%;
      }
    `}
  />
);
//<Loader />
const Layout = ({ children, location }) => {
  return (
    <React.Fragment>
      <LoadProvider>
        <ResizeProvider>
          <GlobalFonts />
          <GlobalStyles />

          <Header />
          <main
            css={css`
              padding-top: 60px;

              @media (min-width: 768px) {
                padding-top: 90px;
              }
            `}
          >
            <Transition location={location}>
              {children}
              <Footer children={children} location={location}/>
            </Transition>
          </main>
        </ResizeProvider>
      </LoadProvider>
    </React.Fragment>
  );
};

export default Layout;
