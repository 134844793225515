import { css } from "@emotion/react";
import React from "react";
import { Div, Container } from "./Markup";
import { Small } from "./Typography";
import SocialShare from "../components/SocialShare";
import COLORS from "../styles/colors"

const Footer = ({children, location}) => {

  // set default title for pages without "queried_data" variable
  let title
  if (children.props.data && typeof children.props.data.queried_data !== "undefined") {
    title = children.props.data.queried_data.title
  } else {
    title = "Текстури"
  }

  return (
    <footer>
      <Div pt="md" pb="md" css={css`border-top: 1px solid ${COLORS.black};`}>
        <Container
          css={css`
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
          `}>
          <SocialShare postTitle={title} postUrl={location.href}/>
          <Small pt="xs">
            &#169; {new Date().getFullYear()} Ахинора А.<br/>Всички права запазени.
          </Small>
        </Container>
      </Div>
    </footer>
  );
};
export default Footer;
