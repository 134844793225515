import { Link } from "gatsby";
import React, {useState} from "react";
import { css } from "@emotion/react";
import { useTransition, animated } from 'react-spring';
import COLORS from "../styles/colors"
import { HEADER_HEIGHTS } from "./Header"
//import MenuIcon from "react-ionicons/lib/MdMenu";
//import MenuIcon from "react-ionicons/lib/IosMenuOutline";
import MenuIcon from "react-ionicons/lib/IosMenu";
import { Div } from "./Markup";


/* for icons see also
 * https://ionic.io/ionicons
 * https://oblador.github.io/react-native-vector-icons/
 * https://github.com/oblador/react-native-vector-icons/blob/master/glyphmaps/Ionicons.json
 */

/**
 * List of menu items
 */
const items = [
  {
    title: "начало",
    url: "/",
  },
  {
    title: "за текстурата",
    url: "/textura",
  },
  {
    title: "за мен",
    url: "/about",
  },
];


/** Menu button
 * 
 * @param {*} param0 
 * @returns 
 */
const MenuItem = ({ title, url, setShowMenu }) => {
  return (
    <Link
      to={url}
      css={css`
        padding-left: 18px;
        font-size: 15px;
        letter-spacing: 0.1em;

        @media (min-width: 768px) {
          font-size: 19px;
          padding-left: 30px;
        }
      `}
      onClick={() => setShowMenu(false)}
//      activeStyle={{ fontWeight: 700 }}
    >
      {title}
    </Link>
  );
};


/** Navigation menu
 * 
 * @returns 
 */
export const MobileMenu = () => {

  // see also https://www.youtube.com/watch?v=5cLcWLRGy5o

  // React state hook
  const [showMenu, setShowMenu] = useState(false)

  // transitions
  const transitionsMask = useTransition(showMenu, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })
  const transitionsMenu = useTransition(showMenu, {
    from: { opacity: 0, transform: 'translateX(100%)' },
    enter: { opacity: 1, transform: 'translateX(0%)' },
    leave: { opacity: 0, transform: 'translateX(100%)' },
  })

  return (
    <nav
      css={css`
        margin-top: 3px;
        display: none;

        @media (max-width: 768px) {
          display: block
        }
      `}
    >
      {/* negate current state */}
      <Div
        css={css`
        :hover {
            cursor: pointer
          }
        `}
        onClick={() => setShowMenu(!showMenu)}
      >
        <MenuIcon/>
      </Div>

      { // add menu mask animation
        transitionsMask((styles, item) => 
          item &&
          <animated.div
            style={styles}
            css={css`
              position: fixed;
              top: 0;
              left: 0;
              background: ${COLORS.black_transparent};
              z-index: 20;
              height: 100vh;
              width: 100vw;
            `}
            onClick={() => setShowMenu(false)}
          >
          </animated.div>
        )
      }

      { // add menu animation
        transitionsMenu((styles, item) => 
          item &&
          <animated.div
            style={styles}
            css={css`
              position: fixed;
              top: ${HEADER_HEIGHTS["sm"]}px;
              right: 0;
              background: ${COLORS.white};
              z-index: 20;
              height: ${(items.length+1)*10}vw;
              width: 80vw;
              box-shadow: 0px 2px 5px 0px ${COLORS.black};
            `}
          >
            <div
              css={css`
                position: absolute;
                left: 50%;
                top: 50%;
                height: 70%;
                transform: translate(-50%, -50%);

                display: flex;
                flex-direction: column;
                text-align: center;
                justify-content: space-between;
              `}
            >
              {items.map((item, index) => (
                <MenuItem key={`item--${index}`} {...item} setShowMenu />
              ))}
            </div>
          </animated.div>
        )
      }
    </nav>
  );
};


export const DesktopMenu = () => {
  return (
    <nav
      css={css`
        margin-top: 3px;
        display: none;

        @media (min-width: 768px) {
          display: block
        }
      `}
    >
      {items.map((item, index) => (
        <MenuItem key={`item--${index}`} {...item} />
      ))}
    </nav>
  );
};