import styled from "styled-components";
import { enableMarginAndPadding } from "../styles/spacing";

// Title large
export const Title = styled("h2")`
  letter-spacing: 0.1em;  // 0.2px
  line-height: 1em;
  font-weight: 400;
  font-size: 36px;

  @media (min-width: 768px) {
    font-size: 48px;
  }

  ${props => enableMarginAndPadding(props)};
`;

// Title medium
export const TitleMd = styled("h2")`
  letter-spacing: 0.1em;  // 0.2px
  line-height: 1.2em;
  font-weight: bold;
  font-size: 24px;

  @media (min-width: 768px) {
    font-size: 24px;
  }

  ${props => enableMarginAndPadding(props)};
`;


// Title small
export const TitleSm = styled("h2")`
  letter-spacing: 0.1em;  // 0.2px
  line-height: 1em;
  font-weight: 400;
  font-size: 31px;

  ${props => enableMarginAndPadding(props)};
`;


// Highlight font sizes
export const Highlight = styled("h3")`
  letter-spacing: 0.1em;
  line-height: 1.2em;
  font-size: 18px;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 18px;
  }

  ${props => enableMarginAndPadding(props)};
`;

// PARAGRAPH
export const Paragraph = styled("p")`
  line-height: 1.65em;
  font-size: 19px;
  white-space: break-spaces;
  letter-spacing: 0.01rem;

  @media (min-width: 768px) {
    font-size: 24px;
  }

  ${props => enableMarginAndPadding(props)};
`;

export const Small = styled("small")`
  letter-spacing: 0.1em;
  line-height: 1.15em;
  font-size: 15px;
  white-space: break-spaces;
  display: block;

  @media (min-width: 768px) {
    font-size: 15px;
  }

  ${props => enableMarginAndPadding(props)};
`;

export const Label = styled("h6")`
  letter-spacing: 0.1em;
  line-height: 1.35em;
  font-size: 15px;
  /*text-transform: uppercase;*/
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 19px;
  }

  ${props => enableMarginAndPadding(props)};
`;
